@import "../../assets/styles/colors.scss";

.modal {
  position: relative;
  background-color: $bg-body;
  border-radius: 18px;
  min-height: 305px;
  padding: 90px !important;
  color: $secondary;
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px !important;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;

  &:hover,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

@import "./fonts.scss";
@import "./colors.scss";

.bold-text {
  font-family: $bold-font;
}

.en-wrapper {
  font-family: $en-regular-font !important;

  .bold-text {
    font-family: $en-bold-font;
  }
}

.bold-amman {
  font-family: $bold-amman-font;
}

.bold-caveat {
  font-family: $bold-caveat-font;
}

.text-brown {
  color: $secondary !important;
}

.text-orange {
  color: $primary;
}

.bg-transparent {
  background-color: transparent !important;
}

body {
  font-family: $regular-font;
}

.rotate {
  transform: rotate(180deg);
}

button {
  &:focus {
    box-shadow: none !important;
  }
}

.tabs-width {
  width: 50%;
  padding-top: 0 !important;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.slick-dots {
  bottom: 30px !important;
}

select {
  -webkit-appearance: none;
}

a {
  text-decoration: none !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

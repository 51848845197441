@font-face {
  font-family: "Cairo-Black";
  src: url("../fonts/Cairo-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-Regular";
  src: url("../fonts/Cairo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Bold";
  src: url("../fonts/Cairo-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Light";
  src: url("../fonts/Cairo-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Caveat-Regular";
  src: url("../fonts/Caveat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Caveat-Bold";
  src: url("../fonts/Caveat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AmmanSans-Bold";
  src: url("../fonts/AmmanSans-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Bold";
  src: url("../fonts/SF-Pro-Text-Bold.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Medium";
  src: url("../fonts/SF-Pro-Text-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Regular";
  src: url("../fonts/SF-Pro-Text-Regular.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lemonada";
  src: url("../fonts/Lemonada-Bold.ttf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$bold-amman-font: "AmmanSans-Bold";
$bold-caveat-font: "Caveat-Bold";
$regular-caveat-font: "Caveat-Regular";
$bold-font: "Cairo-Bold";
$regular-font: "Cairo-Regular";
$light-font: "Cairo-Light";
$en-bold-font: "SF-Pro-Bold";
$en-regular-font: "SF-Pro-Regular";
$en-medium-font: "SF-Pro-Medium";
$Lemonada: "Lemonada";

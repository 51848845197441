@import "../../assets/styles/colors.scss";

.bgImage {
  @media (min-width: 770px) {
    background-image: url(../../assets/images/productBg.png);
    background-size: 160px;
    background-position: top right;
    background-repeat: no-repeat;
    margin-top: 120px;
  }
}

.card {
  position: relative;
  background-color: $bg-body;
  border-radius: 14px;
  height: 338px;
  padding: 2rem 0 1.5rem 0;
  color: $secondary;

  .cardText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 50%;
    height: 100%;
    margin: auto;

    h2 {
      margin: 1rem auto 3.3rem auto;
    }
  }

  button {
    color: $secondary;
    font-size: 20px;
    background-color: transparent;
    border: 0;
    margin-top: 1.5rem;

    &:hover {
      opacity: 0.6;
      color: $secondary;
      background-color: transparent;
    }

    &:focus {
      color: $secondary;
      background-color: transparent;
      box-shadow: none;
    }
  }

  @media (max-width: 768px) {
    height: 100%;
    .cardText {
      width: 90%;
    }
  }
  @media (max-width: 1000px) {
    .cardText {
      width: 90%;
    }
  }
}

.cardGreen {
  @extend .card;
  background-color: $light-green;
  color: $dark-green;

  button {
    color: $dark-green;

    &:hover {
      color: $dark-green;
    }

    &:focus {
      color: $dark-green;
    }
  }
}

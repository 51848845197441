@import "../../assets/styles/colors.scss";
@import "../../assets/styles/fonts.scss";

.riseContainer {
  margin-top: 92px;
  margin-bottom: 155px;
  white-space: pre-line;

  img {
    margin-bottom: 23px;
  }

  @media (max-width: 768px) {
    margin-bottom: 35px;
  }
}

.smallFont {
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
}

.title {
  color: $secondary;
  font-size: 40px;

  @media (max-width: 768px) {
    text-align: center;
  }
}

.description {
  color: $secondary;
  font-size: 35px;

  @media (max-width: 768px) {
    text-align: center;
  }
}

.oneApp {
  font-size: 30px;
}

h4 {
  @media (max-width: 768px) {
    text-align: center;
  }
}

.selectArrow {
  border: 1px solid $secondary;
  background: url(../../assets/icons/down-arrow.svg), transparent;
  background-repeat: no-repeat;

  &:focus {
    outline: none !important;
  }
}

.card {
  height: 160px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0;

  @media (max-width: 768px) {
    height: 132px;
    width: calc(50% - 20px) !important;
    margin: 10px;
  }

  img {
    max-width: 212px;
    max-height: 175px;
    margin: 0 auto;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
}

.main {
  margin-top: 120px;
}

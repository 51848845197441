@import "../../assets/styles/colors.scss";

.topFooter {
  background-color: $primary;
  min-height: 220px;
  font-size: 26px;
  margin-top: 120px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.bottomFooter {
  background-color: $secondary;
  min-height: 220px;
  padding: 75px;

  span {
    font-size: 12px;
    text-align: left;
  }

  @media (max-width: 768px) {
    padding: 75px 30px;
  }
}

.topImg {
  margin-top: -60px;
  margin-bottom: 15px;
  width: 200px;
}

.appBtn {
  padding: 10px 18px !important;

  img {
    height: 20px;
  }
}

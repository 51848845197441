@import "../../assets/styles/colors.scss";
@import "../../assets/styles/fonts.scss";

.input {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px !important;
  height: 55px !important;

  div:first-of-type {
    border: 0;
    align-items: center;

    &:hover {
      border-color: transparent;
      box-shadow: none;
    }
  }

  &:focus,
  &:invalid {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04) !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.carInput {
  background-color: transparent !important;
  border-radius: 0 !important;
  height: 55px !important;
  width: calc(100% / 8) !important;
  border: 0 !important;
  border-bottom: 2px solid rgb(151, 151, 151) !important;
  padding: 3px !important;
  text-align: center;

  div:first-of-type {
    border: 0;
    align-items: center;

    &:hover {
      border-color: transparent;
      box-shadow: none;
    }
  }

  &:focus,
  &:invalid {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04) !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.submit {
  color: $secondary !important;
  padding: 10px 20px;
  font-size: 16px;
  transition-duration: 300ms;
  min-width: 105px;
  height: 40px;

  &:hover {
    color: $secondary !important;
  }

  img {
    height: 12px;
  }
}

.tooltip {
  position: absolute;
  right: 0;
  top: 7px;
}

.label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  position: absolute;
  top: 10px;
}

.selectHeight {
  height: 65px !important;
  padding: 0 8px;
}

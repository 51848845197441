@import "../../assets/styles/colors.scss";
@import "../../assets/styles/fonts.scss";

.bgImage {
  position: relative;
  width: 100%;
  height: 741px;
  background-color: $bg-body;
  background-size: auto 741px, auto 190px;
  background-position: bottom center;
  background-repeat: no-repeat, repeat-x;
  margin-top: 109px;

  p {
    color: #fff;
  }

  @media (max-width: 768px) {
    background-image: none !important;
    height: fit-content;

    p {
      color: $secondary;
    }
  }
}

.homeContainer {
  position: absolute;
  bottom: -20px;
  text-align: center;

  span {
    display: none;
  }

  @media (max-width: 768px) {
    position: relative;
    padding: 50px 30px;

    span {
      display: block;
      font-size: 42px;
      margin-bottom: 44px;
      color: $secondary;
      line-height: 1.2;
    }
  }
}

.appBtn {
  padding: 10px 18px !important;

  img {
    height: 25px;
  }
}

.deliveringHappiness {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 20%;
  width: 23rem;
}
@media (max-width: 768px) {
  .deliveringHappiness {
    width: 223px;
  }
}

.headerText {
  text-align: center;
  padding-top: 8%;
  text-transform: capitalize;
  font-family: $Lemonada;
  color: $secondary;
}

@import "../../assets/styles/colors.scss";

.card {
  background-color: $bg-body;
  border-radius: 14px;
  height: 305px;
  padding: 40px;
  color: $secondary;

  div {
    height: 40px;
  }

  @media (max-width: 768px) {
    border-radius: 0;

    img {
      margin: 0 auto;
    }

    p {
      text-align: center !important;
    }
  }
}

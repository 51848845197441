@import "../../assets/styles/colors.scss";

.wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: $bg-body;
}

.bgImg {
  position: absolute;
  height: 100%;
  width: 50vw;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
    url(../../assets/images/partner-bg.jpg);
  padding-top: 90px;

  @media (max-width: 768px) {
    display: none;
  }
}

.title {
  font-size: 25px;
  margin-bottom: 25px;
}

.imgText {
  font-size: 40px;
  position: relative;

  &:before {
    content: "";
    height: 85px;
    width: 6px;
    background-color: $primary;
    position: absolute;
    bottom: 15px;
  }

  p {
    padding: 0 20px;
  }
}

.sections {
  font-size: 16px;
  padding-bottom: 10px;
}

.active {
  border-bottom: 4px solid $primary;
}

.langBtn {
  color: $secondary !important;
  border: 0 !important;
  margin: 0 10px;
  min-width: 65px;
  max-height: 36px;

  &:hover {
    color: $secondary !important;
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 2px 5px !important;
  }
}

@import "../../assets/styles/colors.scss";
@import "../../assets/styles/fonts.scss";

.navContainer {
  background-color: $bg-body;
  min-height: 109px;
}

.partnerBtn {
  background-color: $light-green !important;
  color: $dark-green !important;
  border: 0;

  &:hover {
    background-color: $light-green !important;
    color: $dark-green !important;
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    margin-top: 20vh;
    padding: 12px !important;
  }
}

.hover {
  &:hover {
    border-bottom: 2px solid $primary !important;
  }
}

.menu {
  border: 0 !important;
  z-index: 100;

  &:focus {
    box-shadow: none;
  }
}

.collapse {
  a {
    color: $secondary !important;
  }

  @media (max-width: 768px) {
    position: relative;
    font-size: 20px;
    padding: 40px 30px;
    height: calc(100vh - 80px);

    a {
      margin: 10px 0;

      button {
        width: 100%;
      }
    }
  }
}

.riderBtn {
  background-color: $light-brown !important;
  color: $secondary !important;
  border: 0;

  &:hover {
    background-color: $light-brown !important;
    color: $secondary !important;
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    padding: 12px !important;
  }
}

.langBtn {
  color: $secondary !important;
  border: 0 !important;
  margin: 0 10px;

  @media (max-width: 768px) {
    margin: 0;
    padding: 2px 5px !important;
  }
}

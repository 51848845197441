.scrollable {
  margin-top: 100px !important;

  @media (max-width: 1000px) {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    overflow-x: scroll;
    height: 250px;
    padding-top: 30px;
  }
}

.scrollableCol {
  @media (max-width: 1000px) {
    width: 120px !important;
  }
}

.main {
  margin-top: 120px;
}

@import "../../assets/styles/colors.scss";

.card {
  position: relative;
  height: 220px;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  img {
    margin-top: -15px;
    width: 100%;
    height: auto;
    // max-height: 160px;
  }

  p {
    font-size: 25px;
    position: absolute;
    bottom: 20px;
  }

  @media (max-width: 1000px) {
    border-radius: 10px;
    height: 160px;
    width: 100%;

    img {
      margin-top: -10px;
    }

    p {
      font-size: 16px;
    }
  }
}
